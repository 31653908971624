
import { defineComponent, nextTick, onMounted } from "vue";
import Details from "@/components/subscriptions/view/Details.vue";
import Events from "@/components/subscriptions/view/Events.vue";
import Invoices from "@/components/subscriptions/view/Invoices.vue";
import Summary from "@/components/subscriptions/view/Summary.vue";
import { StickyComponent } from "@/assets/ts/components";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";

export default defineComponent({
  name: "kt-view-subscription",
  components: {
    Details,
    Events,
    Invoices,
    Summary,
  },
  setup() {
    onMounted(() => {
      setCurrentPageBreadcrumbs("View Subscription", ["Apps", "Subscriptions"]);
      nextTick(() => {
        StickyComponent.reInitialization();
      });
    });
  },
});
